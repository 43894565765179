import React, { useCallback, useMemo } from 'react';
import {
    Button,
    CloneButton,
    CreateButton,
    Datagrid,
    EditButton,
    FilterForm,
    FilterFormProps,
    List,
    Pagination,
    TextField,
    TextInput,
    TopToolbar,
    WrapperField,
} from 'react-admin';
import { Alert, Box } from '@mui/material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { SxProps } from '@mui/system';

import { TResource } from '$app/types';
import { resourceNames } from '$app/constants';

interface IFilterFormProps extends FilterFormProps {
    sx: SxProps;
}

const CustomFilterForm = (props: IFilterFormProps) => <FilterForm {...props} />;

const CustomListActions = () => (
    <TopToolbar>
        <CreateButton variant="contained" color="success" size="medium" label="Добавить пуш с кастомным экспрессом" />
    </TopToolbar>
);

export const CombobetCustomsList: React.FC = () => {
    const postFilters = useMemo(
        () => [
            <TextInput label="Поиск по ID" source="id" alwaysOn fullWidth />,
            <TextInput label="По названию (EN)" source="name" alwaysOn />,
        ],
        [],
    );

    const customRowStyle = useCallback(
        (record: { enabled: boolean }) => ({
            backgroundColor: record.enabled ? '#efe' : '#fff',
        }),
        [],
    );

    return (
        <List
            title={resourceNames[TResource.PushExpresses]}
            actions={<CustomListActions />}
            resource={TResource.PushExpresses}
            pagination={<Pagination rowsPerPageOptions={[]} />}
            perPage={10}
        >
            <Box m="15px" mt="7px">
                <CustomFilterForm filters={postFilters} sx={{ alignItems: 'center', flex: 1 }} />
                <Alert severity="warning">при поиске по ID и названию должно быть точное совпадение</Alert>
            </Box>
            <Datagrid bulkActionButtons={false} rowStyle={customRowStyle}>
                <TextField source="id" label="ID" />
                <TextField source="name" label="Название" />
                <EditButton />
                <CloneButton />
                <WrapperField label="">
                    <Button size="medium" label="Отправить" startIcon={<RocketLaunchIcon />} />
                </WrapperField>
            </Datagrid>
        </List>
    );
};
