import React, { useCallback } from 'react';
import { FunctionField, FunctionFieldProps, TextInput } from 'react-admin';
import { Chip } from '@mui/material';

import { TCombobetTrial } from '$app/types';
import { appleTrialPeriodsData } from '$app/utils';

export const ListItemTrialPeriod: React.FC<Omit<FunctionFieldProps<typeof TextInput>, 'render'>> = (props) => {
    const render = useCallback(({ periodApple }: TCombobetTrial) => {
        const items = [];

        if (periodApple) {
            const periodText = appleTrialPeriodsData.find((item) => {
                return item.id === periodApple;
            })?.name;

            items.push(<Chip label={periodText + ' (AppStore)'} color="primary" variant="outlined" size="small" />);
        }

        return items;
    }, []);

    return <FunctionField render={render} {...props} />;
};
