import React, { useCallback, useMemo } from 'react';
import { Edit, EditProps, useResourceContext } from 'react-admin';

import { TResource } from '$app/types';
import { getPageTitle } from '$app/utils';

interface IProps extends EditProps {
    children: React.ReactNode;
}

export const EditItem: React.FC<IProps> = (props) => {
    const resource = useResourceContext();

    const title = useMemo(() => {
        return getPageTitle(resource as TResource, 'Редактирование');
    }, []);

    const transform = useCallback((data: Record<string, string>) => {
        data['createdAt'] && delete data['createdAt'];
        data['updatedAt'] && delete data['updatedAt'];
        return data;
    }, []);

    return <Edit {...props} mutationMode="pessimistic" transform={transform} title={title} />;
};
