import React from 'react';

import { CreateItem } from '$app/components';

import { CombobetCustomsForm } from './CombobetCustomsForm';

export const CombobetCustomsCreate: React.FC = () => (
    <CreateItem>
        <CombobetCustomsForm />
    </CreateItem>
);
