import React from 'react';

import { CreateItem } from '$app/components';

import { CombobetPushNotificationsForm } from './CombobetPushNotificationsForm';

export const CombobetPushNotificationsCreate: React.FC = () => (
    <CreateItem>
        <CombobetPushNotificationsForm />
    </CreateItem>
);
