import jwtDecode from 'jwt-decode';
import { addRefreshAuthToAuthProvider } from 'react-admin';
import { type AuthProvider } from 'ra-core';

import { ENDPOINT } from '$app/constants';
import { httpClient, refreshAuth } from '$app/utils';

const myAuthProvider: AuthProvider = {
    login: async ({ username: login, password }) => {
        const response = await httpClient(ENDPOINT.LOGIN, {
            method: 'POST',
            body: JSON.stringify({
                login,
                password,
            }),
        });

        const data = await response.json;

        if (data && data.token) {
            localStorage.setItem('token', data.token);
        }
    },

    logout: async () => {
        if (!localStorage.getItem('token')) return;

        try {
            await httpClient(ENDPOINT.LOGOUT, { method: 'POST' });
        } catch {}

        localStorage.removeItem('token');
    },

    checkError: async ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            await Promise.reject();
            return;
        }
        await Promise.resolve();
    },

    checkAuth: async () => {
        localStorage.getItem('token') ? await Promise.resolve() : await Promise.reject();
    },

    getPermissions: async () => {
        const token = localStorage.getItem('token');
        const decodedToken = token ? jwtDecode(token) : null;
        return decodedToken ? await Promise.resolve(decodedToken) : await Promise.reject();
    },
};

export const authProvider = addRefreshAuthToAuthProvider(myAuthProvider, refreshAuth);
