import React from 'react';
import {
    AutocompleteArrayInput,
    BooleanInput,
    NumberInput,
    SelectInput,
    SimpleFormProps,
    TextInput,
} from 'react-admin';
import { Box } from '@mui/material';

import { FormItem, Spacer } from '$app/components';
import { sportsTypeSelectorData } from '$app/utils';

type IProps = Omit<SimpleFormProps, 'children'>;

export const CombobetTemplateForm: React.FC<IProps> = (props) => {
    return (
        <FormItem {...props}>
            <Box flex="1" width="100%">
                <Box display="flex" alignItems="center">
                    <BooleanInput source="enabled" label={'Вкл/Выкл'} />
                </Box>
                <Box display="flex">
                    <TextInput label="Название" source="name" fullWidth />
                    <Spacer />
                    <TextInput label="№ в списке" source="position" />
                </Box>
                <Box display="flex">
                    <AutocompleteArrayInput
                        label="Виды спорта"
                        source="sportTypes"
                        choices={sportsTypeSelectorData}
                        fullWidth
                    />
                </Box>
                <Box display="flex">
                    <NumberInput label="Мин. коэффициент" source="eventCoeffRange[0]" fullWidth step="0.01" min={1.0} />
                    <Spacer />
                    <NumberInput
                        label="Макс. коэффициент"
                        source="eventCoeffRange[1]"
                        fullWidth
                        step="0.01"
                        min={1.0}
                    />
                </Box>
                <Box display="flex">
                    <SelectInput
                        label="Количество событий"
                        source="eventCountRange"
                        emptyText="Любое"
                        choices={[
                            { value: '2_3', name: '2-3' },
                            { value: '4_6', name: '4-6' },
                            { value: '7_10', name: '7-10' },
                            { value: '11_15', name: '11-15' },
                        ]}
                        optionValue="value"
                        format={(value) => value && value.join('_')}
                        parse={(value) => value && value.split('_').map((itemValue: string) => Number(itemValue))}
                        fullWidth
                    />
                    <Spacer />
                    <SelectInput
                        label="Время"
                        source="periodInMinutes"
                        emptyText="Любое"
                        choices={[
                            { value: 60, name: '60м' },
                            { value: 300, name: '5ч' },
                            { value: 1440, name: '24ч' },
                            { value: 2880, name: '2д' },
                            { value: 4320, name: '3д' },
                        ]}
                        optionValue="value"
                        fullWidth
                    />
                </Box>
            </Box>
        </FormItem>
    );
};
