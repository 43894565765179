import React, { useCallback, useMemo } from 'react';
import {
    BooleanInput,
    CreateButton,
    Datagrid,
    DateField,
    DeleteWithConfirmButton,
    EditButton,
    FilterForm,
    FilterFormProps,
    List,
    Pagination,
    TextField,
    TextInput,
    TopToolbar,
} from 'react-admin';
import { Box, Alert } from '@mui/material';
import { SxProps } from '@mui/system';

import { ListItemGeo, ListItemSubscriptionPlan, ListItemToggler } from '$app/components';
import { TResource } from '$app/types';
import { resourceNames } from '$app/constants';

import { ListItemTrialPeriod } from './components';

interface IFilterFormProps extends FilterFormProps {
    sx: SxProps;
}

//TODO: вынести это как отдельный компонент
const CustomFilterForm = (props: IFilterFormProps) => <FilterForm {...props} />;

const CustomListActions = () => (
    <TopToolbar>
        <CreateButton variant="contained" color="success" size="medium" label="Добавить пробный период" />
    </TopToolbar>
);

export const CombobetTrialsList: React.FC = () => {
    const postFilters = useMemo(
        () => [
            <TextInput label="Поиск по ID" source="id" alwaysOn fullWidth />,
            <BooleanInput
                parse={(value) => (value ? true : null)}
                label="Только включенные"
                source="enabled"
                alwaysOn
            />,
        ],
        [],
    );

    const customRowStyle = useCallback(
        (record: { enabled: boolean }) => ({
            backgroundColor: record.enabled ? '#efe' : '#fff',
        }),
        [],
    );

    return (
        <List
            title={resourceNames[TResource.Trials]}
            actions={<CustomListActions />}
            resource={TResource.Trials}
            pagination={<Pagination rowsPerPageOptions={[]} />}
            perPage={10}
        >
            <Box m="15px" mt="7px">
                <CustomFilterForm filters={postFilters} sx={{ alignItems: 'center', flex: 1 }} />
                <Alert severity="warning">при поиске по ID должно быть точное совпадение</Alert>
            </Box>
            <Datagrid bulkActionButtons={false} rowStyle={customRowStyle}>
                <TextField source="id" label="ID" />
                <ListItemSubscriptionPlan label="Тариф" />
                <ListItemTrialPeriod label="Период" />
                <DateField source="datetimeStart" label="Начало" showTime />
                <DateField source="datetimeEnd" label="Конец" showTime />
                <ListItemGeo source="geo" label="Страны" />
                <ListItemToggler resource={TResource.Trials} label="Вкл/Выкл" />
                <EditButton />
                <DeleteWithConfirmButton
                    confirmContent="Вы не сможете восстановить запись. Вы уверены?"
                    translateOptions={{ name: 'пробный период' }}
                />
            </Datagrid>
        </List>
    );
};
