import React from 'react';
import { AutocompleteArrayInput, SelectInput, SimpleFormProps, TextInput } from 'react-admin';
import { Box } from '@mui/material';

import { FormItem, Spacer } from '$app/components';
import { countries, subscriptionPlanData } from '$app/utils';

type IProps = Omit<SimpleFormProps, 'children'>;

export const CombobetDiscountByCodeForm: React.FC<IProps> = (props) => {
    return (
        <FormItem {...props}>
            <Box flex="1" width="100%">
                <Box display="flex" alignItems="center">
                    <SelectInput source="subscriptionPlan" label="Тариф" choices={subscriptionPlanData} required />
                    <Spacer />
                    <TextInput source="price" label="Цена по скидке" type="number" resettable required />
                    <Spacer />
                    <TextInput source="code" label="Код активации" resettable required />
                </Box>
                <Box display="flex">
                    <AutocompleteArrayInput
                        label="Гео"
                        source="geo"
                        choices={countries.map((country) => ({ id: country.code, name: country.name }))}
                        fullWidth
                    />
                </Box>
            </Box>
        </FormItem>
    );
};
