import { useCallback } from 'react';
import { Button } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import UndoIcon from '@mui/icons-material/Undo';

export const BackButton = () => {
    const navigate = useNavigate();

    const goBack = useCallback(() => {
        navigate(-1);
    }, []);

    return (
        <Button
            variant="contained"
            color="warning"
            size="medium"
            label="Вернуться"
            startIcon={<UndoIcon />}
            onClick={goBack}
        />
    );
};
