import React, { useCallback } from 'react';
import { FunctionField, FunctionFieldProps, TextInput } from 'react-admin';
import { Chip } from '@mui/material';

import { TCombobetTrial } from '$app/types';
import { countries } from '$app/utils';

interface IProps extends Omit<FunctionFieldProps<typeof TextInput>, 'render'> {
    source: 'geo';
}

export const ListItemGeo: React.FC<IProps> = (props) => {
    const source = props.source;

    const render = useCallback((record: TCombobetTrial) => {
        if (!record[source]) return null;

        const itemCountryNames = countries.filter((item) => record[source].includes(item.code));

        return itemCountryNames.map((item) => {
            return <Chip label={item.name} color="primary" variant="outlined" />;
        });
    }, []);

    return <FunctionField render={render} {...props} />;
};
