import React from 'react';

import { EditItem } from '$app/components';

import { CombobetTrialForm } from './components';

export const CombobetTrialEdit: React.FC = () => (
    <EditItem>
        <CombobetTrialForm />
    </EditItem>
);
