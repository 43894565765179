import React from 'react';
import { AutocompleteArrayInput, BooleanInput, DateTimeInput, SelectInput, SimpleFormProps } from 'react-admin';
import { Box } from '@mui/material';

import { FormItem, Spacer } from '$app/components';
import { appleTrialPeriodsData, countries, subscriptionPlanData } from '$app/utils';

type IProps = Omit<SimpleFormProps, 'children'>;

export const CombobetTrialForm: React.FC<IProps> = (props) => {
    return (
        <FormItem {...props}>
            <Box flex="1" width="100%">
                <Box display="flex" alignItems="center">
                    <BooleanInput source="enabled" label="Вкл/Выкл" />
                </Box>
                <Box display="flex" alignItems="center">
                    <SelectInput
                        source="subscriptionPlan"
                        label="Тариф"
                        choices={subscriptionPlanData}
                        required
                        fullWidth
                    />
                </Box>
                <Box display="flex">
                    <AutocompleteArrayInput
                        label="Гео"
                        source="geo"
                        choices={countries.map((country) => ({ id: country.code, name: country.name }))}
                        fullWidth
                    />
                </Box>
                <Box display="flex">
                    <DateTimeInput label="Начало" source="datetimeStart" fullWidth />
                    <Spacer />
                    <DateTimeInput label="Конец" source="datetimeEnd" fullWidth />
                </Box>
                <Box display="flex">
                    <SelectInput label="Период" source="periodApple" choices={appleTrialPeriodsData} fullWidth />
                </Box>
            </Box>
        </FormItem>
    );
};
