import { addRefreshAuthToDataProvider } from 'react-admin';
import { type DataProvider } from 'ra-core';
import fakeDataProvider from 'ra-data-fakerest';
import mixerProvider, { Mixer } from 'ra-data-mixer';
import simpleRestProvider from 'ra-data-simple-rest';
import { stringify } from 'query-string';

import { API_URL, ENDPOINT } from '$app/constants';
import { AppleTrialPeriods, SUBSCRIPTION_PLAN, SportTypes, TResource } from '$app/types';
import { httpClient, refreshAuth, temporaryMockTriggerEventsData } from '$app/utils';

export const combobetProviderMock = fakeDataProvider({
    [TResource.Templates]: [
        {
            id: '2',
            name: 'Upcoming events',
            sportTypes: Object.values(SportTypes) as SportTypes[],
            eventCoeffRange: [1.01, 20.0],
            eventCountRange: [4, 6],
            periodInMinutes: 60,
            position: 2,
            enabled: false,
        },
        {
            id: '1',
            name: 'Soccer top tips',
            sportTypes: [SportTypes.Football] as SportTypes[],
            eventCoeffRange: [1.1, 1.5],
            eventCountRange: [7, 10],
            periodInMinutes: 1440,
            position: 1,
            enabled: true,
        },
        {
            id: '3',
            name: 'Super odds',
            sportTypes: Object.values(SportTypes) as SportTypes[],
            eventCoeffRange: [3.0, 30.0],
            eventCountRange: [2, 3],
            periodInMinutes: 1440,
            position: 3,
            enabled: true,
        },
    ],
    [TResource.Trials]: [
        {
            id: '1',
            subscriptionPlan: SUBSCRIPTION_PLAN.ANNUAL,
            geo: ['BR', 'PT'],
            datetimeStart: '2022-01-01 00:00:00+03:00',
            datetimeEnd: '2022-12-31 00:00:00+03:00',
            periodApple: AppleTrialPeriods.ONE_WEEK,
            enabled: true,
        },
        {
            id: '2',
            subscriptionPlan: SUBSCRIPTION_PLAN.ANNUAL,
            geo: ['GB'],
            datetimeStart: '2024-02-01 00:00:00+03:00',
            datetimeEnd: '2024-02-25 23:59:59+03:00',
            periodApple: AppleTrialPeriods.TWO_WEEKS,
            enabled: false,
        },
    ],
    [TResource.OfferCodes]: [
        {
            id: '1',
            subscriptionPlan: SUBSCRIPTION_PLAN.MONTHLY,
            price: 9.99,
            code: 'SKYENG2024',
            geo: ['RU', 'UA', 'BY'],
        },
        {
            id: '2',
            subscriptionPlan: SUBSCRIPTION_PLAN.MONTHLY,
            price: 4.99,
            code: '50DISCOUNT',
            geo: ['BR'],
        },
    ],
    [TResource.Discounts]: [
        {
            id: '1',
            subscriptionPlan: SUBSCRIPTION_PLAN.MONTHLY,
            geo: ['US', 'CA'],
            datetimeStart: '2023-01-24 00:00:00+03:00',
            datetimeEnd: '2024-01-03 00:00:00+03:00',
            price: 6.99,
        },
        {
            id: '2',
            subscriptionPlan: SUBSCRIPTION_PLAN.ANNUAL,
            geo: ['ES', 'CL', 'AR'],
            datetimeStart: '2024-02-01 00:00:00+03:00',
            datetimeEnd: '2024-02-25 23:59:59+03:00',
            price: 44.99,
        },
    ],
    [TResource.SportTypes]: [
        {
            id: '1',
            sportTypes: [SportTypes.Football, SportTypes.Basketball],
            geo: ['US', 'CA'],
        },
        {
            id: '2',
            sportTypes: [SportTypes.TableTennis, SportTypes.CSGO],
            geo: ['RU'],
        },
    ],
    //TODO: status
    [TResource.PushNotifications]: [
        {
            id: '1',
            name: 'Пуш-уведомление, после регистрации',
            geo: ['US', 'CA'],
            event: temporaryMockTriggerEventsData[2].id,
            minutes: 60,
            message: 'Вот и прошел час с подписки, друг',
        },
        {
            id: '2',
            name: 'Через день, после регистрации для Испании',
            geo: ['ES'],
            event: temporaryMockTriggerEventsData[0].id,
            minutes: 60 * 24,
            title: 'Заголовок пуша',
            message: 'День назад ты зарегился',
        },
        {
            id: '3',
            name: '3 часа с момента абстрактного события',
            event: temporaryMockTriggerEventsData[1].id,
            minutes: 180,
            title: 'Заголовок пуша',
            subtitle: 'тестового',
            message: 'Вот и прошло 3 часа',
        },
    ],
    [TResource.PushExpresses]: [
        {
            id: '1',
            name: 'New top combo bet for GB',
            sportTypes: Object.values(SportTypes) as SportTypes[],
            eventCoeffRange: [1.01, 20.0],
            eventCountRange: [4, 6],
            geo: ['GB'],
            title: 'Заголовок пуша',
            subtitle: 'тестового',
            message: 'Основное сообщение',
        },
        {
            id: '2',
            name: 'New top combo bet 2-3 for all',
            sportTypes: Object.values(SportTypes) as SportTypes[],
            eventCoeffRange: [2.01, 3.0],
            eventCountRange: [1, 3],
            message: 'Пуш для всех стран',
        },
    ],
});

export const combobetTemplatesProvider: DataProvider = {
    ...simpleRestProvider(API_URL),
    getList: async (resource, params) => {
        const { field, order } = params.sort;

        const type = resource.replace('combobet-', '');

        const filters: Record<string, string> = {
            id: params.filter.id,
            name: params.filter.name,
        };

        const query = {
            type,
            sort: field,
            order: order === 'ASC' ? 1 : -1,
            ...filters,
        };
        const url = `${ENDPOINT.COMBOBET_GET_TEMPLATES_LIST}?${stringify(query)}`;

        return await httpClient(url).then((response) => {
            const {
                json: { data, meta },
            } = response;

            return {
                data: data.length === 0 ? [] : data[type],
                total: meta.total,
            };
        });
    },

    getOne: async (_, params) =>
        await httpClient(`${ENDPOINT.COMBOBET_GET_TEMPLATE}?id=${params.id}`).then(({ json }) => ({
            data: json,
        })),

    update: async (_, params) =>
        await httpClient(ENDPOINT.COMBOBET_UPDATE_TEMPLATE, {
            method: 'PUT',
            body: JSON.stringify({ id: params.id, ...params.data }),
        }).then(({ json }) => ({ data: json })),

    create: async (resource, params) => {
        const type = resource.replace('combobet-', '');
        return await httpClient(ENDPOINT.COMBOBET_CREATE_TEMPLATE, {
            method: 'POST',
            body: JSON.stringify({ type, ...params.data }),
        }).then(({ json }) => ({ data: json }));
    },

    delete: async (_, params) =>
        await httpClient(`${ENDPOINT.COMBOBET_DELETE_TEMPLATE}?id=${params.id}`, {
            method: 'DELETE',
            headers: new Headers({
                'Content-Type': 'text/plain',
            }),
        }).then(({ json }) => ({ data: json })),
};

const providers: Record<TResource, DataProvider> = {
    [TResource.Templates]: combobetProviderMock,
    [TResource.Trials]: combobetProviderMock,
    [TResource.OfferCodes]: combobetProviderMock,
    [TResource.Discounts]: combobetProviderMock,
    [TResource.SportTypes]: combobetProviderMock,
    [TResource.PushNotifications]: combobetProviderMock,
    [TResource.PushExpresses]: combobetProviderMock,
};

const providersConfig: Mixer = (resource) => {
    if (providers[resource as TResource]) return providers[resource as TResource];
    else return undefined;
};

export const dataProvider: DataProvider = addRefreshAuthToDataProvider(mixerProvider(providersConfig), refreshAuth);
