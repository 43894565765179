import React from 'react';

import { EditItem } from '$app/components';

import { CombobetTemplateForm } from './CombobetTemplateForm';

export const CombobetTemplateEdit: React.FC = () => (
    <EditItem>
        <CombobetTemplateForm />
    </EditItem>
);
