import React, { useCallback } from 'react';
import { FunctionField, FunctionFieldProps, TextInput, useDataProvider, useNotify } from 'react-admin';
import { Input } from '@mui/material';

import { TCombobetPresetTemplate, TResource } from '$app/types';
import { debounce } from '$app/utils';

import styles from './styles';

export const ListItemPositionInput: React.FC<Omit<FunctionFieldProps<typeof TextInput>, 'render'>> = (props) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const onComplete = useCallback(async (record: TCombobetPresetTemplate, eventTarget: HTMLInputElement) => {
        const { data: previousData } = await dataProvider.getOne(TResource.Templates as string, { id: record.id });

        try {
            await dataProvider.update(TResource.Templates as string, {
                id: record.id,
                data: { ...previousData, position: eventTarget.value },
                previousData: previousData,
            });
        } catch {
            notify('Ошибка на сервере', {
                type: 'error',
            });
            eventTarget.value = String(record.position);
        }
    }, []);

    const debouncedOnComplete = debounce(onComplete, 500);

    const render = useCallback((record: TCombobetPresetTemplate) => {
        const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
            debouncedOnComplete(record, event.target);
        };

        return (
            <Input
                type="number"
                inputProps={{
                    step: 1,
                    min: 1,
                }}
                onChange={onChange}
                defaultValue={record.position}
                sx={styles.chanceInput}
            />
        );
    }, []);

    return <FunctionField label="Шанс" render={render} {...props} />;
};
