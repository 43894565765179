import React, { useCallback, useState } from 'react';
import { FunctionField, FunctionFieldProps, TextInput, useDataProvider, useListContext, useNotify } from 'react-admin';
import { Switch } from '@mui/material';

import { TCombobetPresetTemplate } from '$app/types';

interface IProps extends Omit<FunctionFieldProps<typeof TextInput>, 'render'> {
    onUpdate?: () => void;
}

export const ListItemToggler: React.FC<IProps> = ({ resource, onUpdate, ...props }) => {
    const { refetch } = useListContext();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [checked, setChecked] = useState<boolean | undefined>(undefined);

    const render = useCallback(
        (record: TCombobetPresetTemplate) => {
            const ref: React.RefObject<HTMLInputElement> = React.createRef();
            const label = record.enabled ? 'Включен' : 'Выключен';

            const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
                setChecked(event.target.checked);

                const { data: previousData } = await dataProvider.getOne(resource as string, { id: record.id });
                try {
                    await dataProvider.update(resource as string, {
                        id: record.id,
                        data: { ...previousData, enabled: event.target.checked },
                        previousData: previousData,
                    });

                    refetch();
                    onUpdate && onUpdate();
                } catch {
                    notify('Ошибка на сервере', {
                        type: 'error',
                    });

                    setChecked(record.enabled);
                }
            };

            return (
                <>
                    <Switch
                        inputRef={ref}
                        color="primary"
                        onChange={onChange}
                        checked={checked ?? record.enabled}
                        defaultChecked={record.enabled}
                    />
                    {label}
                </>
            );
        },
        [checked],
    );

    return <FunctionField render={render} {...props} />;
};
