import React from 'react';

import { EditItem } from '$app/components';

import { CombobetDiscountByCodeForm } from './components';

export const CombobetDiscountByCodeEdit: React.FC = () => (
    <EditItem>
        <CombobetDiscountByCodeForm />
    </EditItem>
);
