const styles = {
    chanceInput: {
        width: 50,
        input: {
            textAlign: 'center',
        },
    },
};

export default styles;
