export function debounce<T extends unknown[]>(fn: (...args: T) => void, wait: number): (...args: T) => void {
    let timeoutId: NodeJS.Timeout;

    return (...args: T): void => {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            fn(...args);
        }, wait);
    };
}
