import React from 'react';
import { AutocompleteArrayInput, SelectArrayInput, SimpleFormProps } from 'react-admin';
import { Box } from '@mui/material';

import { FormItem } from '$app/components';
import { countries, sportsTypeSelectorData } from '$app/utils';

type IProps = Omit<SimpleFormProps, 'children'>;

export const CombobetSportTypesForm: React.FC<IProps> = (props) => {
    return (
        <FormItem {...props}>
            <Box flex="1" width="100%">
                <Box display="flex">
                    <SelectArrayInput
                        source="sportTypes"
                        choices={sportsTypeSelectorData}
                        label="Виды спорта по приоритетности"
                        fullWidth
                    />
                </Box>
                <Box display="flex">
                    <AutocompleteArrayInput
                        label="Гео"
                        source="geo"
                        choices={countries.map((country) => ({ id: country.code, name: country.name }))}
                        fullWidth
                    />
                </Box>
            </Box>
        </FormItem>
    );
};
