import React from 'react';
import { SaveButton, SimpleForm, SimpleFormProps, Toolbar } from 'react-admin';

import { BackButton } from '$app/components';

const FormActions = ({ leftButtons }: { leftButtons?: React.ReactNode }) => {
    return (
        <Toolbar sx={{ gap: '8px' }}>
            {leftButtons}
            <SaveButton alwaysEnable />
            <BackButton />
        </Toolbar>
    );
};

interface IProps extends SimpleFormProps {
    leftButtons?: React.ReactNode;
}

export const FormItem: React.FC<IProps> = ({ leftButtons, ...props }) => {
    return (
        <SimpleForm toolbar={<FormActions leftButtons={leftButtons} />} {...props}>
            {props.children}
        </SimpleForm>
    );
};
