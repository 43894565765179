import React from 'react';
import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import { CustomLayout } from './components';
import { locale } from './constants';
import { authProvider, dataProvider } from './services';
import {
    CombobetDiscountByCodeCreate,
    CombobetDiscountByCodeEdit,
    CombobetDiscountsByCodeList,
    CombobetDiscountCreate,
    CombobetDiscountEdit,
    CombobetDiscountsList,
    CombobetCustomsCreate,
    CombobetCustomsEdit,
    CombobetCustomsList,
    CombobetPushNotificationsCreate,
    CombobetPushNotificationsEdit,
    CombobetPushNotificationsList,
    CombobetTemplateCreate,
    CombobetTemplateEdit,
    CombobetTemplatesList,
    CombobetSportTypesCreate,
    CombobetSportTypesEdit,
    CombobetSportTypesList,
    CombobetTrialCreate,
    CombobetTrialEdit,
    CombobetTrialsList,
} from './pages';
import { TResource } from '$app/types';

const i18nProvider = polyglotI18nProvider(() => locale, 'ru');

const App: React.FC = () => (
    <Admin
        i18nProvider={i18nProvider}
        title="Combobet Admin"
        dataProvider={dataProvider}
        authProvider={authProvider}
        layout={CustomLayout}
    >
        <Resource
            name={TResource.Templates}
            list={CombobetTemplatesList}
            edit={CombobetTemplateEdit}
            create={CombobetTemplateCreate}
        />
        <Resource
            name={TResource.Trials}
            list={CombobetTrialsList}
            edit={CombobetTrialEdit}
            create={CombobetTrialCreate}
        />
        <Resource
            name={TResource.OfferCodes}
            list={CombobetDiscountsByCodeList}
            edit={CombobetDiscountByCodeEdit}
            create={CombobetDiscountByCodeCreate}
        />
        <Resource
            name={TResource.Discounts}
            list={CombobetDiscountsList}
            edit={CombobetDiscountEdit}
            create={CombobetDiscountCreate}
        />
        <Resource
            name={TResource.SportTypes}
            list={CombobetSportTypesList}
            edit={CombobetSportTypesEdit}
            create={CombobetSportTypesCreate}
        />
        <Resource
            name={TResource.PushNotifications}
            list={CombobetPushNotificationsList}
            edit={CombobetPushNotificationsEdit}
            create={CombobetPushNotificationsCreate}
        />
        <Resource
            name={TResource.PushExpresses}
            list={CombobetCustomsList}
            edit={CombobetCustomsEdit}
            create={CombobetCustomsCreate}
        />
    </Admin>
);

export default App;
