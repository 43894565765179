import React from 'react';

import { CreateItem } from '$app/components';

import { CombobetTemplateForm } from './CombobetTemplateForm';

export const CombobetTemplateCreate: React.FC = () => (
    <CreateItem>
        <CombobetTemplateForm />
    </CreateItem>
);
