import React from 'react';
import { AutocompleteArrayInput, Button, NumberInput, SelectInput, SimpleFormProps, TextInput } from 'react-admin';
import { Box, Card, CardContent, CardHeader, Divider, Stack } from '@mui/material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

import { FormItem, Spacer } from '$app/components';
import { countries, sportsTypeSelectorData } from '$app/utils';

type IProps = Omit<SimpleFormProps, 'children'>;

export const CombobetCustomsForm: React.FC<IProps> = (props) => {
    return (
        <FormItem
            {...props}
            leftButtons={
                <Button
                    variant="contained"
                    color="error"
                    size="medium"
                    label="Сохранить и отправить пуш"
                    startIcon={<RocketLaunchIcon />}
                />
            }
        >
            <Box sx={{ width: '100%' }}>
                <Box display="flex">
                    <TextInput label="Название (произвольное)" source="name" fullWidth />
                </Box>
                <Box display="flex">
                    <AutocompleteArrayInput
                        label="Виды спорта"
                        source="sportTypes"
                        choices={sportsTypeSelectorData}
                        fullWidth
                    />
                </Box>
                <Box display="flex">
                    <NumberInput label="Мин. коэффициент" source="eventCoeffRange[0]" fullWidth step="0.01" min={1.0} />
                    <Spacer />
                    <NumberInput
                        label="Макс. коэффициент"
                        source="eventCoeffRange[1]"
                        fullWidth
                        step="0.01"
                        min={1.0}
                    />
                </Box>
                <Box display="flex">
                    <SelectInput
                        label="Количество событий"
                        source="eventCountRange"
                        emptyText="Любое"
                        choices={[
                            { value: '2_3', name: '2-3' },
                            { value: '4_6', name: '4-6' },
                            { value: '7_10', name: '7-10' },
                            { value: '11_15', name: '11-15' },
                        ]}
                        optionValue="value"
                        format={(value) => value && value.join('_')}
                        parse={(value) => value && value.split('_').map((itemValue: string) => Number(itemValue))}
                        fullWidth
                    />
                    <Spacer />
                    <SelectInput
                        label="Время"
                        source="periodInMinutes"
                        emptyText="Любое"
                        choices={[
                            { value: 60, name: '60м' },
                            { value: 300, name: '5ч' },
                            { value: 1440, name: '24ч' },
                            { value: 2880, name: '2д' },
                            { value: 4320, name: '3д' },
                        ]}
                        optionValue="value"
                        fullWidth
                    />
                </Box>
                <AutocompleteArrayInput
                    label="Гео (для отправки пуша)"
                    source="geo"
                    choices={countries.map((country) => ({ id: country.code, name: country.name }))}
                    fullWidth
                />
            </Box>
            <Divider />
            <Card sx={{ width: '100%' }}>
                <CardHeader title="Контект пуш-уведомления" />
                <CardContent>
                    <TextInput label="Title" source="title" fullWidth />
                    <TextInput label="Subtitle" source="subtitle" fullWidth />
                    <TextInput label="Message" source="message" fullWidth required />
                </CardContent>
            </Card>
        </FormItem>
    );
};
