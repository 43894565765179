import React from 'react';

import { CreateItem } from '$app/components';

import { CombobetSportTypesForm } from './components';

export const CombobetSportTypesCreate: React.FC = () => (
    <CreateItem>
        <CombobetSportTypesForm />
    </CreateItem>
);
