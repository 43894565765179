import { resourceNames } from '$app/constants';
import { AppleTrialPeriods, SUBSCRIPTION_PLAN, SportTypes, TResource } from '$app/types';

export const appleTrialPeriodsData: { id: AppleTrialPeriods; name: string }[] = [
    { id: AppleTrialPeriods.ONE_DAY, name: '1 день' },
    { id: AppleTrialPeriods.THREE_DAYS, name: '3 дня' },
    { id: AppleTrialPeriods.ONE_WEEK, name: '1 неделя' },
    { id: AppleTrialPeriods.TWO_WEEKS, name: '2 недели' },
    { id: AppleTrialPeriods.ONE_MONTH, name: '1 месяц' },
    { id: AppleTrialPeriods.TWO_MONTHS, name: '2 месяца' },
    { id: AppleTrialPeriods.THREE_MONTHS, name: '3 месяца' },
    { id: AppleTrialPeriods.SIX_MONTHS, name: '6 месяцев' },
    { id: AppleTrialPeriods.ONE_YEAR, name: '1 год' },
];

export const countries: { code: string; name: string }[] = [
    { code: 'AF', name: 'Афганистан' },
    { code: 'AL', name: 'Албания' },
    { code: 'DZ', name: 'Алжир' },
    { code: 'AS', name: 'Американское Самоа' },
    { code: 'AD', name: 'Андорра' },
    { code: 'AO', name: 'Ангола' },
    { code: 'AI', name: 'Ангилья' },
    { code: 'AQ', name: 'Антарктида' },
    { code: 'AG', name: 'Антигуа и Барбуда' },
    { code: 'AR', name: 'Аргентина' },
    { code: 'AM', name: 'Армения' },
    { code: 'AW', name: 'Аруба' },
    { code: 'AU', name: 'Австралия' },
    { code: 'AT', name: 'Австрия' },
    { code: 'AZ', name: 'Азербайджан' },
    { code: 'BS', name: 'Багамы' },
    { code: 'BH', name: 'Бахрейн' },
    { code: 'BD', name: 'Бангладеш' },
    { code: 'BB', name: 'Барбадос' },
    { code: 'BY', name: 'Беларусь' },
    { code: 'BE', name: 'Бельгия' },
    { code: 'BZ', name: 'Белиз' },
    { code: 'BJ', name: 'Бенин' },
    { code: 'BM', name: 'Бермуды' },
    { code: 'BT', name: 'Бутан' },
    { code: 'BO', name: 'Боливия' },
    { code: 'BQ', name: 'Бонайре, Синт-Эстатиус и Саба' },
    { code: 'BA', name: 'Босния и Герцеговина' },
    { code: 'BW', name: 'Ботсвана' },
    { code: 'BV', name: 'Остров Буве' },
    { code: 'BR', name: 'Бразилия' },
    { code: 'IO', name: 'Британская территория в Индийском океане' },
    { code: 'BN', name: 'Бруней-Даруссалам' },
    { code: 'BG', name: 'Болгария' },
    { code: 'BF', name: 'Буркина-Фасо' },
    { code: 'BI', name: 'Бурунди' },
    { code: 'CV', name: 'Кабо-Верде' },
    { code: 'KH', name: 'Камбоджа' },
    { code: 'CM', name: 'Камерун' },
    { code: 'CA', name: 'Канада' },
    { code: 'KY', name: 'Каймановы острова' },
    { code: 'CF', name: 'Центральноафриканская Республика' },
    { code: 'TD', name: 'Чад' },
    { code: 'CL', name: 'Чили' },
    { code: 'CN', name: 'Китай' },
    { code: 'CX', name: 'Остров Рождества' },
    { code: 'CC', name: 'Кокосовые острова (Килинг)' },
    { code: 'CO', name: 'Колумбия' },
    { code: 'KM', name: 'Коморы' },
    { code: 'CG', name: 'Конго' },
    { code: 'CD', name: 'Конго, Демократическая Республика' },
    { code: 'CK', name: 'Острова Кука' },
    { code: 'CR', name: 'Коста-Рика' },
    { code: 'CI', name: 'Кот-д’Ивуар' },
    { code: 'HR', name: 'Хорватия' },
    { code: 'CU', name: 'Куба' },
    { code: 'CW', name: 'Кюрасао' },
    { code: 'CY', name: 'Кипр' },
    { code: 'CZ', name: 'Чехия' },
    { code: 'DK', name: 'Дания' },
    { code: 'DJ', name: 'Джибути' },
    { code: 'DM', name: 'Доминика' },
    { code: 'DO', name: 'Доминиканская Республика' },
    { code: 'EC', name: 'Эквадор' },
    { code: 'EG', name: 'Египет' },
    { code: 'SV', name: 'Сальвадор' },
    { code: 'GQ', name: 'Экваториальная Гвинея' },
    { code: 'ER', name: 'Эритрея' },
    { code: 'EE', name: 'Эстония' },
    { code: 'SZ', name: 'Эсватини' },
    { code: 'ET', name: 'Эфиопия' },
    { code: 'FK', name: 'Фолклендские острова (Мальвинские)' },
    { code: 'FO', name: 'Фарерские острова' },
    { code: 'FJ', name: 'Фиджи' },
    { code: 'FI', name: 'Финляндия' },
    { code: 'FR', name: 'Франция' },
    { code: 'GF', name: 'Французская Гвиана' },
    { code: 'PF', name: 'Французская Полинезия' },
    { code: 'TF', name: 'Французские Южные территории' },
    { code: 'GA', name: 'Габон' },
    { code: 'GM', name: 'Гамбия' },
    { code: 'GE', name: 'Грузия' },
    { code: 'DE', name: 'Германия' },
    { code: 'GH', name: 'Гана' },
    { code: 'GI', name: 'Гибралтар' },
    { code: 'GR', name: 'Греция' },
    { code: 'GL', name: 'Гренландия' },
    { code: 'GD', name: 'Гренада' },
    { code: 'GP', name: 'Гваделупа' },
    { code: 'GU', name: 'Гуам' },
    { code: 'GT', name: 'Гватемала' },
    { code: 'GG', name: 'Гернси' },
    { code: 'GN', name: 'Гвинея' },
    { code: 'GW', name: 'Гвинея-Бисау' },
    { code: 'GY', name: 'Гайана' },
    { code: 'HT', name: 'Гаити' },
    { code: 'HM', name: 'Остров Херд и Острова Макдональд' },
    { code: 'VA', name: 'Святейший Престол (Государство-город Ватикан)' },
    { code: 'HN', name: 'Гондурас' },
    { code: 'HK', name: 'Гонконг' },
    { code: 'HU', name: 'Венгрия' },
    { code: 'IS', name: 'Исландия' },
    { code: 'IN', name: 'Индия' },
    { code: 'ID', name: 'Индонезия' },
    { code: 'IR', name: 'Иран' },
    { code: 'IQ', name: 'Ирак' },
    { code: 'IE', name: 'Ирландия' },
    { code: 'IM', name: 'Остров Мэн' },
    { code: 'IL', name: 'Израиль' },
    { code: 'IT', name: 'Италия' },
    { code: 'JM', name: 'Ямайка' },
    { code: 'JP', name: 'Япония' },
    { code: 'JE', name: 'Джерси' },
    { code: 'JO', name: 'Иордания' },
    { code: 'KZ', name: 'Казахстан' },
    { code: 'KE', name: 'Кения' },
    { code: 'KI', name: 'Кирибати' },
    { code: 'KP', name: 'Корейская Народно-Демократическая Республика' },
    { code: 'KR', name: 'Корея, Республика' },
    { code: 'KW', name: 'Кувейт' },
    { code: 'KG', name: 'Киргизия' },
    { code: 'LA', name: 'Лаос' },
    { code: 'LV', name: 'Латвия' },
    { code: 'LB', name: 'Ливан' },
    { code: 'LS', name: 'Лесото' },
    { code: 'LR', name: 'Либерия' },
    { code: 'LY', name: 'Ливия' },
    { code: 'LI', name: 'Лихтенштейн' },
    { code: 'LT', name: 'Литва' },
    { code: 'LU', name: 'Люксембург' },
    { code: 'MO', name: 'Макао' },
    { code: 'MG', name: 'Мадагаскар' },
    { code: 'MW', name: 'Малави' },
    { code: 'MY', name: 'Малайзия' },
    { code: 'MV', name: 'Мальдивы' },
    { code: 'ML', name: 'Мали' },
    { code: 'MT', name: 'Мальта' },
    { code: 'MH', name: 'Маршалловы Острова' },
    { code: 'MQ', name: 'Мартиника' },
    { code: 'MR', name: 'Мавритания' },
    { code: 'MU', name: 'Маврикий' },
    { code: 'YT', name: 'Майотта' },
    { code: 'MX', name: 'Мексика' },
    { code: 'FM', name: 'Микронезия (Федеративные Штаты)' },
    { code: 'MD', name: 'Молдова' },
    { code: 'MC', name: 'Монако' },
    { code: 'MN', name: 'Монголия' },
    { code: 'ME', name: 'Черногория' },
    { code: 'MS', name: 'Монтсеррат' },
    { code: 'MA', name: 'Марокко' },
    { code: 'MZ', name: 'Мозамбик' },
    { code: 'MM', name: 'Мьянма' },
    { code: 'NA', name: 'Намибия' },
    { code: 'NR', name: 'Науру' },
    { code: 'NP', name: 'Непал' },
    { code: 'NL', name: 'Нидерланды' },
    { code: 'NC', name: 'Новая Каледония' },
    { code: 'NZ', name: 'Новая Зеландия' },
    { code: 'NI', name: 'Никарагуа' },
    { code: 'NE', name: 'Нигер' },
    { code: 'NG', name: 'Нигерия' },
    { code: 'NU', name: 'Ниуэ' },
    { code: 'NF', name: 'Остров Норфолк' },
    { code: 'MP', name: 'Северные Марианские острова' },
    { code: 'NO', name: 'Норвегия' },
    { code: 'OM', name: 'Оман' },
    { code: 'PK', name: 'Пакистан' },
    { code: 'PW', name: 'Палау' },
    { code: 'PS', name: 'Палестина, Государство' },
    { code: 'PA', name: 'Панама' },
    { code: 'PG', name: 'Папуа-Новая Гвинея' },
    { code: 'PY', name: 'Парагвай' },
    { code: 'PE', name: 'Перу' },
    { code: 'PH', name: 'Филиппины' },
    { code: 'PN', name: 'Питкэрн' },
    { code: 'PL', name: 'Польша' },
    { code: 'PT', name: 'Португалия' },
    { code: 'PR', name: 'Пуэрто-Рико' },
    { code: 'QA', name: 'Катар' },
    { code: 'MK', name: 'Северная Македония' },
    { code: 'RO', name: 'Румыния' },
    { code: 'RU', name: 'Россия' },
    { code: 'RW', name: 'Руанда' },
    { code: 'RE', name: 'Реюньон' },
    { code: 'BL', name: 'Сен-Бартелеми' },
    { code: 'SH', name: 'Остров Святой Елены, Остров Вознесения и Тристан-да-Кунья' },
    { code: 'KN', name: 'Сент-Китс и Невис' },
    { code: 'LC', name: 'Сент-Люсия' },
    { code: 'MF', name: 'Сен-Мартен (Французская часть)' },
    { code: 'PM', name: 'Сен-Пьер и Микелон' },
    { code: 'VC', name: 'Сент-Винсент и Гренадины' },
    { code: 'WS', name: 'Самоа' },
    { code: 'SM', name: 'Сан-Марино' },
    { code: 'ST', name: 'Сан-Томе и Принсипи' },
    { code: 'SA', name: 'Саудовская Аравия' },
    { code: 'SN', name: 'Сенегал' },
    { code: 'RS', name: 'Сербия' },
    { code: 'SC', name: 'Сейшелы' },
    { code: 'SL', name: 'Сьерра-Леоне' },
    { code: 'SG', name: 'Сингапур' },
    { code: 'SX', name: 'Синт-Мартен (нидерландская часть)' },
    { code: 'SK', name: 'Словакия' },
    { code: 'SI', name: 'Словения' },
    { code: 'SB', name: 'Соломоновы Острова' },
    { code: 'SO', name: 'Сомали' },
    { code: 'ZA', name: 'Южная Африка' },
    { code: 'GS', name: 'Южная Георгия и Южные Сандвичевы острова' },
    { code: 'SS', name: 'Южный Судан' },
    { code: 'ES', name: 'Испания' },
    { code: 'LK', name: 'Шри-Ланка' },
    { code: 'SD', name: 'Судан' },
    { code: 'SR', name: 'Суринам' },
    { code: 'SJ', name: 'Шпицберген и Ян-Майен' },
    { code: 'SE', name: 'Швеция' },
    { code: 'CH', name: 'Швейцария' },
    { code: 'SY', name: 'Сирийская Арабская Республика' },
    { code: 'TW', name: 'Тайвань' },
    { code: 'TJ', name: 'Таджикистан' },
    { code: 'TZ', name: 'Танзания' },
    { code: 'TH', name: 'Таиланд' },
    { code: 'TL', name: 'Восточный Тимор' },
    { code: 'TG', name: 'Того' },
    { code: 'TK', name: 'Токелау' },
    { code: 'TO', name: 'Тонга' },
    { code: 'TT', name: 'Тринидад и Тобаго' },
    { code: 'TN', name: 'Тунис' },
    { code: 'TR', name: 'Турция' },
    { code: 'TM', name: 'Туркменистан' },
    { code: 'TC', name: 'Теркс и Кайкос' },
    { code: 'TV', name: 'Тувалу' },
    { code: 'UG', name: 'Уганда' },
    { code: 'UA', name: 'Украина' },
    { code: 'AE', name: 'Объединенные Арабские Эмираты' },
    { code: 'GB', name: 'Великобритания' },
    { code: 'US', name: 'Соединенные Штаты' },
    { code: 'UM', name: 'Малые отдаленные острова США' },
    { code: 'UY', name: 'Уругвай' },
    { code: 'UZ', name: 'Узбекистан' },
    { code: 'VU', name: 'Вануату' },
    { code: 'VE', name: 'Венесуэла' },
    { code: 'VN', name: 'Вьетнам' },
    { code: 'VG', name: 'Виргинские острова (Британские)' },
    { code: 'VI', name: 'Виргинские острова (США)' },
    { code: 'WF', name: 'Уоллис и Футуна' },
    { code: 'EH', name: 'Западная Сахара' },
    { code: 'YE', name: 'Йемен' },
    { code: 'ZM', name: 'Замбия' },
    { code: 'ZW', name: 'Зимбабве' },
];

export const subscriptionPlanData: { id: SUBSCRIPTION_PLAN; name: string }[] = [
    { id: SUBSCRIPTION_PLAN.ANNUAL, name: 'Год' },
    { id: SUBSCRIPTION_PLAN.MONTHLY, name: 'Месяц' },
];

export const sportsTypeSelectorData: { id: SportTypes; name: string }[] = [
    { id: SportTypes.Football, name: 'Футбол' },
    { id: SportTypes.Basketball, name: 'Баскетбол' },
    { id: SportTypes.Hockey, name: 'Хоккей' },
    { id: SportTypes.Tennis, name: 'Теннис' },
    { id: SportTypes.Baseball, name: 'Бейсбол' },
    { id: SportTypes.CSGO, name: 'CSGO' },
    { id: SportTypes.Dota, name: 'Dota2' },
    { id: SportTypes.Handball, name: 'Гандбол' },
    { id: SportTypes.MMA, name: 'MMA' },
    { id: SportTypes.TableTennis, name: 'Настольный теннис' },
    { id: SportTypes.Volleyball, name: 'Волейбол' },
    { id: SportTypes.MiniFootball, name: 'Футзал' },
    { id: SportTypes.AmericanFootball, name: 'Американский футбол' },
];

export const temporaryMockTriggerEventsData = [
    { id: 'REGISTER', name: 'Регистрация' },
    { id: 'EVENT2', name: 'Второе тестовое событие' },
    { id: 'PURCHASE', name: 'Приобретена подписка' },
];

export const getPageTitle = (resource: TResource, pages: string[] | string) => {
    let items = [];

    if (resourceNames[resource]) {
        items.push(resourceNames[resource]);
    }

    if (typeof pages === 'string') {
        items.push(pages);
    } else {
        items = [...items, ...pages];
    }

    return items.join('. ');
};
