import { fetchUtils } from 'ra-core';

import { API_URL } from '$app/constants';
import type { ENDPOINT } from '$app/constants';

export const httpClient = (ENDPOINT: ENDPOINT | string, options: fetchUtils.Options = {}) => {
    const token = localStorage.getItem('token');
    if (token) {
        options.user = {
            authenticated: true,
            token: 'Bearer ' + token,
        };
    }

    return fetchUtils.fetchJson(API_URL + '/' + ENDPOINT, options);
};
