import React from 'react';

import { EditItem } from '$app/components';

import { CombobetSportTypesForm } from './components';

export const CombobetSportTypesEdit: React.FC = () => (
    <EditItem>
        <CombobetSportTypesForm />
    </EditItem>
);
