import React from 'react';

import { EditItem } from '$app/components';

import { CombobetCustomsForm } from './CombobetCustomsForm';

export const CombobetCustomsEdit: React.FC = () => (
    <EditItem>
        <CombobetCustomsForm />
    </EditItem>
);
