import React from 'react';
import {
    CreateButton,
    Datagrid,
    DeleteWithConfirmButton,
    EditButton,
    List,
    Pagination,
    TextField,
    TopToolbar,
} from 'react-admin';

import { ListItemGeo } from '$app/components';
import { TResource } from '$app/types';
import { resourceNames } from '$app/constants';

const CustomListActions = () => (
    <TopToolbar>
        <CreateButton variant="contained" color="success" size="medium" label="Добавить приоритеты видов спорта" />
    </TopToolbar>
);

export const CombobetSportTypesList: React.FC = () => {
    return (
        <List
            title={resourceNames[TResource.SportTypes]}
            actions={<CustomListActions />}
            resource={TResource.SportTypes}
            pagination={<Pagination rowsPerPageOptions={[]} />}
            perPage={10}
        >
            <Datagrid bulkActionButtons={false}>
                <TextField source="id" label="ID" />
                <ListItemGeo source="geo" label="Страны" />
                <EditButton />
                <DeleteWithConfirmButton
                    confirmContent="Вы не сможете восстановить запись. Вы уверены?"
                    translateOptions={{ name: 'пробный период' }}
                />
            </Datagrid>
        </List>
    );
};
