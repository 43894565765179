import jwtDecode from 'jwt-decode';

import { ENDPOINT } from '$app/constants';

import { httpClient } from './httpClient';

export const refreshAuth = async () => {
    const token = localStorage.getItem('token') as string;

    if (!token) return Promise.resolve();

    const decodedToken = jwtDecode(token) as { exp: number };
    if (decodedToken.exp < Math.round(Date.now() / 1000)) {
        const response = await httpClient(ENDPOINT.TOKEN_REFRESH, { method: 'POST' });

        const data = response.json;

        if (data.token) {
            localStorage.setItem('token', data.token);
        }
    } else {
        return Promise.resolve();
    }
};
