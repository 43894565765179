import React, { useMemo } from 'react';
import { Create, CreateProps, useNotify, useRedirect, useResourceContext } from 'react-admin';

import { TResource } from '$app/types';
import { getPageTitle } from '$app/utils';

interface IProps extends CreateProps {
    children: React.ReactNode;
}

export const CreateItem: React.FC<IProps> = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const resource = useResourceContext();

    const title = useMemo(() => {
        return getPageTitle(resource as TResource, 'Создание');
    }, []);

    const onSuccess = (data: { id: string }) => {
        notify('ra.notification.created', {
            messageArgs: { smart_count: 1 },
        });
        redirect('list', resource, data.id, data);
    };

    return <Create mutationOptions={{ onSuccess }} {...props} title={title} />;
};
