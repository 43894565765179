import React from 'react';

import { EditItem } from '$app/components';

import { CombobetDiscountForm } from './components';

export const CombobetDiscountEdit: React.FC = () => (
    <EditItem>
        <CombobetDiscountForm />
    </EditItem>
);
