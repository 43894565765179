import React from 'react';

import { CreateItem } from '$app/components';

import { CombobetTrialForm } from './components';

export const CombobetTrialCreate: React.FC = () => (
    <CreateItem>
        <CombobetTrialForm />
    </CreateItem>
);
