import React, { useCallback, useMemo } from 'react';
import {
    CreateButton,
    Datagrid,
    DeleteWithConfirmButton,
    EditButton,
    FilterForm,
    FilterFormProps,
    List,
    NumberField,
    Pagination,
    TextField,
    TextInput,
    TopToolbar,
} from 'react-admin';
import { Box, Alert } from '@mui/material';
import { SxProps } from '@mui/system';

import { ListItemGeo, ListItemSubscriptionPlan } from '$app/components';
import { TResource } from '$app/types';
import { resourceNames } from '$app/constants';

interface IFilterFormProps extends FilterFormProps {
    sx: SxProps;
}

//TODO: вынести это как отдельный компонент
const CustomFilterForm = (props: IFilterFormProps) => <FilterForm {...props} />;

const CustomListActions = () => (
    <TopToolbar>
        <CreateButton variant="contained" color="success" size="medium" label="Добавить кодовое предложение" />
    </TopToolbar>
);

export const CombobetDiscountsByCodeList: React.FC = () => {
    const postFilters = useMemo(
        () => [<TextInput label="Поиск по коду активации" source="code" alwaysOn fullWidth />],
        [],
    );

    const customRowStyle = useCallback(
        (record: { enabled: boolean }) => ({
            backgroundColor: record.enabled ? '#efe' : '#fff',
        }),
        [],
    );

    return (
        <List
            title={resourceNames[TResource.OfferCodes]}
            actions={<CustomListActions />}
            resource={TResource.OfferCodes}
            pagination={<Pagination rowsPerPageOptions={[]} />}
            perPage={10}
        >
            <Box m="15px" mt="7px">
                <CustomFilterForm filters={postFilters} sx={{ alignItems: 'center', flex: 1 }} />
                <Alert severity="warning">при поиске по коду активации должно быть точное совпадение</Alert>
            </Box>
            <Datagrid bulkActionButtons={false} rowStyle={customRowStyle}>
                <TextField source="id" label="ID" />
                <ListItemSubscriptionPlan label="Тариф" />
                <TextField source="code" label="Код активации" />
                <NumberField source="price" label="Стоимость" options={{ style: 'currency', currency: 'USD' }} />
                <ListItemGeo source="geo" label="Страны" />
                <EditButton />
                <DeleteWithConfirmButton
                    confirmContent="Вы не сможете восстановить запись. Вы уверены?"
                    translateOptions={{ name: 'пробный период' }}
                />
            </Datagrid>
        </List>
    );
};
