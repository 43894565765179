export enum AppleTrialPeriods {
    ONE_DAY = 'ONE_DAY',
    THREE_DAYS = 'THREE_DAYS',
    ONE_WEEK = 'ONE_WEEK',
    TWO_WEEKS = 'TWO_WEEKS',
    ONE_MONTH = 'ONE_MONTH',
    TWO_MONTHS = 'TWO_MONTHS',
    THREE_MONTHS = 'THREE_MONTHS',
    SIX_MONTHS = 'SIX_MONTHS',
    ONE_YEAR = 'ONE_YEAR',
}

export enum SportTypes {
    Football = 'soccer',
    Basketball = 'basketball',
    Hockey = 'ice-hockey',
    Tennis = 'tennis',
    Baseball = 'baseball',
    CSGO = 'csgo',
    Dota = 'dota2',
    Handball = 'handball',
    MMA = 'mma',
    TableTennis = 'table-tennis',
    Volleyball = 'volleyball',
    MiniFootball = 'futsal',
    AmericanFootball = 'american-football',
}

export enum SUBSCRIPTION_PLAN {
    ANNUAL = 'annual',
    MONTHLY = 'monthly',
}

export enum TResource {
    Templates = 'templates',
    Trials = 'trials',
    OfferCodes = 'discounts-by-code',
    Discounts = 'discounts',
    SportTypes = 'sport-types',
    PushNotifications = 'push-notifications',
    PushExpresses = 'push-custom',
}

export type SendHeaders = {
    'Content-Type': string;
    authorization?: string;
};

export type TCombobetPresetTemplate = {
    id: string;
    name: string;
    sportTypes: SportTypes[];
    eventCoeffRange: [number, number];
    eventCountRange: [number, number];
    periodInMinutes: number;
    position: number;
    enabled: boolean;
};

export type TCombobetTrial = {
    id: string;
    geo: string[];
    datetimeStart: string;
    datetimeEnd: string;
    periodApple: AppleTrialPeriods;
    subscriptionPlan: SUBSCRIPTION_PLAN;
    enabled: boolean;
};
