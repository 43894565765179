export const API_URL = 'https://admin.betsnetwork.org/api';

export enum ENDPOINT {
    //WARNING: несуществующие эндпоинты
    COMBOBET_GET_TEMPLATES_LIST = 'combobet/template/list',
    COMBOBET_GET_TEMPLATE = 'combobet/template',
    COMBOBET_CREATE_TEMPLATE = 'combobet/template',
    COMBOBET_UPDATE_TEMPLATE = 'combobet/template',
    COMBOBET_DELETE_TEMPLATE = 'combobet/template',

    LOGIN = 'login',
    LOGOUT = 'logout',
    TOKEN_REFRESH = 'refresh',
    UPLOAD_IMAGE = 'upload-image',
}
