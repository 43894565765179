import React from 'react';
import { AutocompleteArrayInput, Button, NumberInput, SelectInput, SimpleFormProps, TextInput } from 'react-admin';
import { Box, Card, CardContent, CardHeader, Divider } from '@mui/material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

import { FormItem, Spacer } from '$app/components';
import { countries, temporaryMockTriggerEventsData } from '$app/utils';

type IProps = Omit<SimpleFormProps, 'children'>;

export const CombobetPushNotificationsForm: React.FC<IProps> = (props) => {
    return (
        <FormItem
            {...props}
            leftButtons={
                <Button
                    variant="contained"
                    color="error"
                    size="medium"
                    label="Сохранить и отправить пуш"
                    startIcon={<RocketLaunchIcon />}
                />
            }
        >
            <Box sx={{ width: '100%' }}>
                <Box display="flex">
                    <SelectInput
                        label="Триггерное событие"
                        source="event"
                        choices={temporaryMockTriggerEventsData}
                        fullWidth
                        required
                    />
                    <Spacer />
                    <NumberInput
                        label="С момента события (минут)"
                        source="minutes"
                        fullWidth
                        step="1"
                        min={10}
                        required
                    />
                </Box>
                <AutocompleteArrayInput
                    label="Гео"
                    source="geo"
                    choices={countries.map((country) => ({ id: country.code, name: country.name }))}
                    fullWidth
                />
            </Box>
            <Divider />
            <Card sx={{ width: '100%' }}>
                <CardHeader title="Контект пуш-уведомления" />
                <CardContent>
                    <TextInput label="Title" source="title" fullWidth />
                    <TextInput label="Subtitle" source="subtitle" fullWidth />
                    <TextInput label="Message" source="message" fullWidth required />
                </CardContent>
            </Card>
        </FormItem>
    );
};
