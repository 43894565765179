import { Layout, type LayoutProps, Menu, type MenuProps } from 'react-admin';

import { resourceNames } from '$app/constants';

import styles from './Layout.module.css';

function CustomMenu(props: MenuProps) {
    return (
        <Menu {...props}>
            <h3 className={styles.subheader}>Генератор экспрессов</h3>
            {Object.entries(resourceNames).map(([resourceKey, resourceName]) => (
                <Menu.Item to={`/${resourceKey}`} primaryText={resourceName} />
            ))}
        </Menu>
    );
}

export function CustomLayout(props: LayoutProps) {
    return <Layout {...props} menu={CustomMenu} />;
}
