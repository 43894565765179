import React from 'react';

import { CreateItem } from '$app/components';

import { CombobetDiscountForm } from './components';

export const CombobetDiscountCreate: React.FC = () => (
    <CreateItem>
        <CombobetDiscountForm />
    </CreateItem>
);
