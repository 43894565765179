import React, { useCallback } from 'react';
import { FunctionField, FunctionFieldProps, TextInput } from 'react-admin';

import { TCombobetTrial } from '$app/types';
import { subscriptionPlanData } from '$app/utils';

export const ListItemSubscriptionPlan: React.FC<Omit<FunctionFieldProps<typeof TextInput>, 'render'>> = (props) => {
    const render = useCallback((record: TCombobetTrial) => {
        console.log(record, subscriptionPlanData);
        return subscriptionPlanData.find((item) => item.id === record.subscriptionPlan)?.name;
    }, []);

    return <FunctionField render={render} {...props} />;
};
