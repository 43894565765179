import React from 'react';

import { EditItem } from '$app/components';

import { CombobetPushNotificationsForm } from './CombobetPushNotificationsForm';

export const CombobetPushNotificationsEdit: React.FC = () => (
    <EditItem>
        <CombobetPushNotificationsForm />
    </EditItem>
);
