import React from 'react';

import { CreateItem } from '$app/components';

import { CombobetDiscountByCodeForm } from './components';

export const CombobetDiscountByCodeCreate: React.FC = () => (
    <CreateItem>
        <CombobetDiscountByCodeForm />
    </CreateItem>
);
