import React, { useCallback, useMemo } from 'react';
import {
    BooleanInput,
    CloneButton,
    CreateButton,
    Datagrid,
    DeleteWithConfirmButton,
    EditButton,
    FilterForm,
    FilterFormProps,
    List,
    Pagination,
    TextField,
    TextInput,
    TopToolbar,
} from 'react-admin';
import { Alert, Box } from '@mui/material';
import { SxProps } from '@mui/system';

import { ListItemToggler } from '$app/components';
import { TResource } from '$app/types';
import { resourceNames } from '$app/constants';

import { ListItemPositionInput } from './components';

interface IFilterFormProps extends FilterFormProps {
    sx: SxProps;
}

const CustomFilterForm = (props: IFilterFormProps) => <FilterForm {...props} />;

const CustomListActions = () => (
    <TopToolbar>
        <CreateButton variant="contained" color="success" size="medium" label="Добавить пресет" />
    </TopToolbar>
);

export const CombobetTemplatesList: React.FC = () => {
    const postFilters = useMemo(
        () => [
            <TextInput label="Поиск по ID" source="id" alwaysOn fullWidth />,
            <TextInput label="По названию (EN)" source="name" alwaysOn />,
            <BooleanInput
                parse={(value) => (value ? true : null)}
                label="Только включенные"
                source="enabled"
                alwaysOn
            />,
        ],
        [],
    );

    const customRowStyle = useCallback(
        (record: { enabled: boolean }) => ({
            backgroundColor: record.enabled ? '#efe' : '#fff',
        }),
        [],
    );

    return (
        <List
            title={resourceNames[TResource.Templates]}
            actions={<CustomListActions />}
            resource={TResource.Templates}
            pagination={<Pagination rowsPerPageOptions={[]} />}
            perPage={10}
        >
            <Box m="15px" mt="7px">
                <CustomFilterForm filters={postFilters} sx={{ alignItems: 'center', flex: 1 }} />
                <Alert severity="warning">при поиске по ID и названию должно быть точное совпадение</Alert>
            </Box>
            <Datagrid bulkActionButtons={false} rowStyle={customRowStyle}>
                <ListItemPositionInput label="Позиция" />
                <TextField source="id" label="ID" />
                <TextField source="name" label="Название" />
                <ListItemToggler resource={TResource.Templates} label="Вкл/Выкл" />
                <EditButton />
                <CloneButton />
                <DeleteWithConfirmButton
                    confirmContent="Вы не сможете восстановить запись. Вы уверены?"
                    translateOptions={{ name: 'пресет' }}
                />
            </Datagrid>
        </List>
    );
};
